
import { IonPage, IonHeader, IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter, } from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import Header from "@/components/header.vue";
import { skipRouter } from "@/router";
import { userinfo } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "Setting",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const menus = [
      {
        label: "隐私政策",
        path: "privacy-agreement",
      },
      {
        label: "使用条款",
        path: "use-clause",
      },
      {
        label: "联系我们",
        path: "contact-us",
      },
    ];
    //退出登录
    const loginout = async () => {
      local.clear();
      skipRouter("/tabs/member");
    };

    return { skipRouter, menus, chevronForward, loginout };
  },
};
